<script setup lang="ts">
import type { IManagerFieldEmits, IManagerFieldProps } from './types'
import {
  useFieldCache,
  useFieldCalculation,
  useFieldCast,
  useFieldValues,
  useField,
} from '@manager'

const props = withDefaults(defineProps<IManagerFieldProps>(), {
  parentValue: () => ({}),
  parentPath: '',
})
const emit = defineEmits<IManagerFieldEmits>()

const { modelValue, parentValue, handleChange, handleBlur } = useField(
  props,
  emit,
)

const values = useFieldValues(props.node, props.parentPath)

const { cast, castPlugin } = useFieldCast(props.node)

// Initialize calculated field, after setting default value
const { registerCalculated } = useFieldCalculation(props.node, parentValue)
registerCalculated()

useFieldCache(props.node, parentValue)

// Clear value on options change
watch(values, () => {
  const hasOption = values.value.some(({ value }) => value === modelValue.value)
  if (!hasOption) {
    modelValue.value = cast(props.node.defaultValue)
  }
})
</script>

<template>
  <ManagerField v-bind="props">
    <template #default="{ id, name, type, label }">
      <Tooltip
        class="bg-gray-950 text-xs"
        outer-class="grow flex"
        :content="node.tooltip ? node.tooltip : undefined"
        placement="top"
      >
        <FormKit
          :id="id"
          v-model="modelValue"
          :type="type"
          :name="name"
          :label="label"
          :options="values"
          label-by="key"
          track-by="value"
          outer-class="w-full"
          label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
          :plugins="[castPlugin]"
          @input="handleChange"
          @blur="handleBlur"
        >
          <template v-if="node.prefix" #prefix>
            <span
              class="flex h-10 items-center rounded-l-[5px] bg-gray-700 px-2.5 pt-px text-xs"
            >
              {{ node.prefix }}
            </span>
          </template>
          <template v-if="node.suffix" #suffix>
            <span
              class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
            >
              {{ node.suffix }}
            </span>
          </template>
        </FormKit>
      </Tooltip>
    </template>
  </ManagerField>
</template>
