import type { INodeField, INodeFieldValue } from '@manager'
import { useDependentOn } from '@manager'
import { normalizeValues } from './utils'

export const useFieldValues = (
  node: INodeField,
  parentPath: string | undefined,
) => {
  const { evaluate } = useDependentOn(node, parentPath)

  const normalizedValues = normalizeValues(node)

  return computed(() => {
    const _values: INodeFieldValue[] = []

    for (const { dependentOn, values } of normalizedValues.value) {
      if (evaluate(dependentOn)) {
        _values.push(...values)
      }
    }

    return _values
  })
}
