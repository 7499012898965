import type {
  INodeField,
  INodeFieldConditionalValues,
  INodeFieldValue,
} from '@manager'

export function isConditionalValue(
  value: INodeFieldValue | INodeFieldConditionalValues,
): value is INodeFieldConditionalValues {
  return (
    'dependentOn' in value && value.dependentOn && value.dependentOn.length > 0
  )
}

export function normalizeValues(node: INodeField) {
  return computed<INodeFieldConditionalValues[]>(() => {
    // If the node doesn't have field values, return an empty array
    if (!hasFieldValues(node) || !node.values) return []

    return node.values.map<INodeFieldConditionalValues>((value) => {
      if (isConditionalValue(value)) return value
      return { dependentOn: [], values: [value] }
    })
  })
}
